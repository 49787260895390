import FormJaivana from 'dashboard_jaivana_v1';

/**
 *
 * @author Juan Camilo Villa
 * @modified 07-01-2025
*/

/**
 * CustomVendingMaquinasDispensaciones
**/
class CustomVendingMaquinasDispensaciones extends FormJaivana.form {


   constructor(props) {

        super(props);
        this.initForm = this.initForm.bind(this);
        this.gridOptionsTbPrincipal = Object.assign({},this.gridOptions);
        this.arrayPrincipal = [];
        /* this.getDispensations = this.getDispensations.bind(this); */
        this.successGetDispensations = this.successGetDispensations.bind(this);
        this.successGetVendingMaquinasDispensaciones = this.successGetVendingMaquinasDispensaciones.bind(this);

        /* this.newGettDispensations = this.newGettDispensations.bind(this); */

        this.newGettDispensationsPage = this.newGettDispensationsPage.bind(this);
        this.successGetNewDispensations = this.successGetNewDispensations.bind(this);

        this.getVendingMaquinasDispensaciones = this.getVendingMaquinasDispensaciones.bind(this);
        
        this.newGettDispensationsByPage = this.newGettDispensationsByPage.bind(this);
        this.successGetNewDispensationsByPage = this.successGetNewDispensationsByPage.bind(this);
        this.mostrarMensajeGenerando = this.mostrarMensajeGenerando.bind(this);
    }

    initForm() {
       console.log('CustomVendingMaquinasDispensaciones,  @version: jdesk_1.01.0001, @author: Juan Camilo Villa');
        /* this.getField('btn_dispensaciones').setClick(this.getDispensations); */
        this.getField('btn_dispensaciones').setClick(this.newGettDispensationsPage);
        this.getField('dateTo').setOnChange(() => this.getField('tabla_dispensaciones').toggle(false));
        this.getField('dateFrom').setOnChange(() => this.getField('tabla_dispensaciones').toggle(false));
        this.getVendingMaquinasDispensaciones();
        /* this.newGettDispensations(); */
        
    }

    newGettDispensations(){
        
        let startDate = this.getField('dateFrom').getValue() + 'T00:00:00-05:00';
        let endDate = this.getField('dateTo').getValue() + 'T23:59:20-05:00';
 /*        let startDate = '2025-01-20T00:00:00-05:00';
        let endDate = '2025-01-20T23:59:20-05:00'; */
        let datos={ datos: {
            nit: '',
            /* nit: '1054997232', uno de los nit que arroja la peticion actual*/
            /* nit: '890903858', nit que muestra las máquinas en el formulario vending_maquinas */
            dateFrom: startDate,
            dateTo: endDate,
            pageNumber: '1'
        }};

        this.generalFormatPmv = { tipo_servicio: 'inte-dispensaciones', operacion: 'getNewDispensations', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successGetDispensations,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
                
        });

    }

    getVendingMaquinasDispensaciones(){
        let datos={ datos: {}};
        this.mostrarMensajeGenerando();
        this.generalFormatPmv = { tipo_servicio: 'inte-dispensaciones', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successGetVendingMaquinasDispensaciones,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
                
        });

    }


    successGetVendingMaquinasDispensaciones(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            console.log('tabla_dispensaciones  ', data.data);
            this.getField('tabla_dispensaciones').toggle(true);
            this.arrayPrincipal = structuredClone(data.data);
            this.gridOptionsTbPrincipal['rowData'] = this.arrayPrincipal;
            let configCell = new Map();
            configCell.set('id_dispensacion', {cellRenderer:function(props){return props.data.id_dispensacion}, sortable: false, filter: false, field: 'Id Dispensacion' });
            configCell.set('amount', {cellRenderer:function(props){return props.data.amount}, sortable: false, filter: false, field: 'Monto' });
            configCell.set('product_transaction_type_name', {cellRenderer:function(props){return props.data.product_transaction_type_name}, sortable: false, filter: false, field: 'Nombre Tipo Producto Transacción' });
            configCell.set('so_product_name', {cellRenderer:function(props){return props.data.so_product_name}, sortable: false, filter: false, field: 'Nombre Producto' });
            configCell.set('so_product_erp_code', {cellRenderer:function(props){return props.data.so_product_erp_code}, sortable: false, filter: false, field: 'Código Producto' });
            configCell.set('so_product_description', {cellRenderer:function(props){return props.data.so_product_description}, sortable: false, filter: false, field: 'Descripción Producto' });
            configCell.set('sc_dependency_name', {cellRenderer:function(props){return props.data.sc_dependency_name}, sortable: false, filter: false, field: 'Nombre Dependencia' });
            configCell.set('sc_department_name', {cellRenderer:function(props){return props.data.sc_department_name}, sortable: false, filter: false, field: 'Nombre Departamento' });
            configCell.set('sc_user_profile_name', {cellRenderer:function(props){return props.data.sc_user_profile_name}, sortable: false, filter: false, field: 'Nombre Perfil' });
            configCell.set('sc_cost_center_name', {cellRenderer:function(props){return props.data.sc_cost_center_name}, sortable: false, filter: false, field: 'Nombre Centro De Costo' });
            configCell.set('sc_cost_center_code', {cellRenderer:function(props){return props.data.sc_cost_center_code}, sortable: false, filter: false, field: 'Código Centro De Costo' });
            configCell.set('vm_friendly_name', {cellRenderer:function(props){return props.data.vm_friendly_name}, sortable: false, filter: false, field: 'Nombre Descriptivo' });
            configCell.set('vm_slot_number', {cellRenderer:function(props){return props.data.vm_slot_number}, sortable: false, filter: false, field: 'Número Ranura' });
            configCell.set('user_identitycard_number', {cellRenderer:function(props){return props.data.user_identitycard_number}, sortable: false, filter: false, field: 'Nit' });
            configCell.set('user_name', {cellRenderer:function(props){return props.data.user_name}, sortable: false, filter: false, field: 'Nombre Usuario' });
            configCell.set('user_complete_name', {cellRenderer:function(props){return props.data.user_complete_name}, sortable: false, filter: false, field: 'Nombre Completo' });
            /* configCell.set('user_img_path_s3', {cellRenderer:function(props){return props.data.user_img_path_s3}, sortable: false, filter: false, field: 'Imagen Usuario' }); */
            configCell.set('sc_dispensation_rule_name', {cellRenderer:function(props){return props.data.sc_dispensation_rule_name}, sortable: false, filter: false, field: 'Regla Dispensación' });
            configCell.set('storagedate', {cellRenderer:function(props){return props.data.storagedate}, sortable: false, filter: false, field: 'Fecha Almacenamiento' });
            configCell.set('transactiondate', {cellRenderer:function(props){return props.data.transactiondate}, sortable: false, filter: false, field: 'Fecha Transacción' });
            configCell.set('payment_type', {cellRenderer:function(props){return props.data.payment_type}, sortable: false, filter: false, field: 'Tipo Pago' });
            configCell.set('numero_remision', {cellRenderer:function(props){return props.data.numero_remision}, sortable: false, filter: false, field: 'Numero Remision' });
            configCell.set('fecha_remision', {cellRenderer:function(props){return props.data.fecha_remision}, sortable: false, filter: false, field: 'Fecha Remision' });
            configCell.set('codigo_sucursal', {cellRenderer:function(props){return props.data.codigo_sucursal}, sortable: false, filter: false, field: 'Código Sucursal' });
            configCell.set('integrado', {cellRenderer:function(props){return props.data.integrado}, sortable: false, filter: false, field: 'Fecha Integración' });

            this.getField('tabla_dispensaciones').initData(this.gridOptionsTbPrincipal, configCell);
            
/*             
            configCell.set('customer_code', {cellRenderer:function(props){return props.data.customer_code}, sortable: false, filter: false, field: 'Código Cliente' });
            configCell.set('customer_name', {cellRenderer:function(props){return props.data.customer_name},sortable: false, filter: false, field: 'Nombre Cliente' });
            configCell.set('identity_card_number', {cellRenderer:function(props){return props.data.identity_card_number} ,sortable: false, filter: false, field: 'Nit' });
            configCell.set('user_first_name', {cellRenderer:function(props){return props.data.user_first_name} ,sortable: false, filter: false, field: 'Primer Nombre' });
            configCell.set('user_middle_name', {cellRenderer:function(props){return props.data.user_middle_name} ,sortable: false, filter: false, field: 'Segundo Nombre' });
            configCell.set('user_last_name1', {cellRenderer:function(props){return props.data.user_last_name1} ,sortable: false, filter: false, field: 'Primer Apellido'});
            configCell.set('user_last_name2', { cellRenderer:function(props){return props.data.user_last_name2} ,sortable: false, filter: false, field: 'Segundo Apellido' });
            configCell.set('department', { cellRenderer:function(props){return props.data.department} ,sortable: false, filter: false, field: 'Departamento' });
            configCell.set('user_profile', { cellRenderer:function(props){return props.data.user_profile} ,sortable: false, filter: false, field: 'Perfil de Usuario' });
            configCell.set('cost_center', { cellRenderer:function(props){return props.data.cost_center} ,sortable: false, filter: false, field: 'Centro de Costo' });
            configCell.set('vm_name', { cellRenderer:function(props){return props.data.vm_name} ,sortable: false, filter: false, field: 'Nombre Máquina' });
            configCell.set('vm_serial_number', { cellRenderer:function(props){return props.data.vm_serial_number} ,sortable: false, filter: false, field: 'Número Serial' });
            configCell.set('slot_number', { cellRenderer:function(props){return props.data.slot_number} ,sortable: false, filter: false, field: 'Número Ranura' });
            configCell.set('product_code', { cellRenderer:function(props){return props.data.product_code} ,sortable: false, filter: false, field: 'Código Producto' });
            configCell.set('product_name', { cellRenderer:function(props){return props.data.product_name} ,sortable: false, filter: false, field: 'Nombre Producto' });
            configCell.set('amount', { cellRenderer:function(props){return props.data.amount} ,sortable: false, filter: false, field: 'Monto' });
            configCell.set('pre_tax_price', { cellRenderer:function(props){return props.data.pre_tax_price} ,sortable: false, filter: false, field: 'Precio Previo Impuesto' });
            configCell.set('tax_value', { cellRenderer:function(props){return props.data.tax_value} ,sortable: false, filter: false, field: 'Valor Impuesto' });
            configCell.set('dispensation_rule', { cellRenderer:function(props){return props.data.dispensation_rule} ,sortable: false, filter: false, field: 'Norma Dispensación' });
            configCell.set('transaction_date', { cellRenderer:function(props){return props.data.transaction_date} ,sortable: false, filter: false, field: 'Fecha Transacción' });
            configCell.set('storage_date', { cellRenderer:function(props){return props.data.storage_date} ,sortable: false, filter: false, field: 'Fecha Almacenamiento' });
            configCell.set('integrado', { cellRenderer:function(props){return props.data.integrado} ,sortable: false, filter: false, });
            

             */

        }else{
            this.gridOptionsTbPrincipal['rowData'] = [];
            this.getField('tabla_dispensaciones').toggle(false);
        }

    }


     successGetDispensations(data){
        console.log('data totalPages ', data);
        console.log('data estado_p ', data.estado_p);

        if (data.estado_p === 200) {
            this.arrayPrincipal = structuredClone(data.data);
            console.log('arrayPrincipal ====ª ',this.arrayPrincipal);
            this.getVendingMaquinasDispensaciones();

        }
    }

    newGettDispensationsByPage(page){
        let startDate = this.getField('dateFrom').getValue() + 'T00:00:00-05:00';
        let endDate = this.getField('dateTo').getValue() + 'T23:59:20-05:00';
        let datos={ datos: {
            nit: '',
            dateFrom: startDate,
            dateTo: endDate,
            pageNumber: page.toString(),
        }};
        console.log('datos enviados ', datos);

        this.generalFormatPmv = { tipo_servicio: 'inte-dispensaciones', operacion: 'getNewDispensationsByPage', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successGetNewDispensationsByPage,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
                
        });

    }

    successGetNewDispensationsByPage(data){
        console.log('data retornada ', data);
        if (data.estado_p === 200) {
            this.arrayPrincipal.concat(data.data);
            console.log('array ', this.arrayPrincipal.length);
            this.getVendingMaquinasDispensaciones();
        }
        
    }

    

///Metodos que no funcionan desde el back

newGettDispensationsPage(){
        this.mostrarMensajeGenerando();

        this.arrayPrincipal = [];
        this.gridOptionsTbPrincipal['rowData'] = [];
        let startDate = this.getField('dateFrom').getValue() + 'T00:00:00-05:00';
        let endDate = this.getField('dateTo').getValue() + 'T23:59:20-05:00';

        let datos={ datos: {
            nit: '',
            dateFrom: startDate,
            dateTo: endDate,
            pageNumber: '1',
        }};
        console.log('datos enviados ', datos);

        this.generalFormatPmv = { tipo_servicio: 'inte-dispensaciones', operacion: 'getNewDispensationsPage', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successGetNewDispensations,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
                
        });

    }

    successGetNewDispensations(data){
        console.log('data retornada ', data);
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
/*             this.arrayPrincipal.concat(data.data);
*/
            console.log('array ', this.arrayPrincipal.length);
        }
        this.getVendingMaquinasDispensaciones();
    }

    mostrarMensajeGenerando() {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(() => {
        });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }


}

FormJaivana.addController('inte-dispensaciones',CustomVendingMaquinasDispensaciones);
export default CustomVendingMaquinasDispensaciones;
import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomDescuentosPorGrupo
 * @author: Patricia López Sánchez
 * @version: jdesk_1.01.0005
 **/
class CustomDescuentosPorGrupo extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.gridOptionsComponentes     = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentes1    = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentes2    = Object.assign({}, this.gridOptions);
        this.opcionDescuentoPorGrupo    = this.opcionDescuentoPorGrupo.bind(this);
        this.opcionDescuentoPorSubGrupo = this.opcionDescuentoPorSubGrupo.bind(this);
        this.opcionDescuentoPorLineas   = this.opcionDescuentoPorLineas.bind(this);
        this.traerdescuentos            = this.traerdescuentos.bind(this);
        this.successTraerDescuento      = this.successTraerDescuento.bind(this);
        this.validarFechas              = this.validarFechas.bind(this);
        this.guardardescuentos          = this.guardardescuentos.bind(this);
        this.successGuardardescuentos   = this.successGuardardescuentos.bind(this);
        this.limpiar                    = this.limpiar.bind(this);
        this.eliminarDescuento          = this.eliminarDescuento.bind(this);
        this.successEliminarDescuento   = this.successEliminarDescuento.bind(this);
        this.confirmEliminarDescuento	= this.confirmEliminarDescuento.bind(this);
        this.mostrarCampos	            = this.mostrarCampos.bind(this);
        this.setButtonDelete	        = this.setButtonDelete.bind(this);
        this.setButtonEdit	            = this.setButtonEdit.bind(this);
        this.validarunico	            = this.validarunico.bind(this);
        this.successvalidarunico	    = this.successvalidarunico.bind(this);
        this.abrirmodal	                = this.abrirmodal.bind(this);
        this.ocualtarCampos	            = this.ocualtarCampos.bind(this);
        this.modificardescuento	        = this.modificardescuento.bind(this);
        this.successmodificardescuento	= this.successmodificardescuento.bind(this);
        this.agregarhistorico	        = this.agregarhistorico.bind(this);
        this.successagregarhistorico	= this.successagregarhistorico.bind(this);
        this.fecha_actual               = '';
        this.opcionDescuentoPorMarcas = this.opcionDescuentoPorMarcas.bind(this);
        this.gridOptionsComponentes3 = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentes4 = Object.assign({}, this.gridOptions);
        this.opcionDescuentoPorProd = this.opcionDescuentoPorProd.bind(this);
    }

    initForm(){
        console.log('Formulario CustomDescuentosPorGrupo,  @version: jdesk_1.01.0005, @author:Patricia López Sánchez')
        if(this.props.data[0].opcion_sub_seccion === 1){
            this.opcionDescuentoPorGrupo();
            this.getField('nombre_formulario').setValue("DESCUENTO POR GRUPO");
        }else if(this.props.data[0].opcion_sub_seccion === 2){
            this.opcionDescuentoPorSubGrupo();
            this.getField('nombre_formulario').setValue("DESCUENTO POR SUBGRUPO");
        }else if(this.props.data[0].opcion_sub_seccion === 3){
            this.opcionDescuentoPorLineas();
            this.getField('nombre_formulario').setValue("DESCUENTO POR LÍNEAS");
        }else if (this.props.data[0].opcion_sub_seccion === 4) {
            this.opcionDescuentoPorMarcas();
            this.getField('nombre_formulario').setValue("DESCUENTO POR MARCAS");
        }else if (this.props.data[0].opcion_sub_seccion === 5) {
            this.opcionDescuentoPorProd();
            this.getField('nombre_formulario').setValue("DESCUENTO POR PRODUCTOS");
        }
        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        if(mes<10){
            mes='0'+mes;
        }
        if(dia<10){
          dia='0'+dia;
        }
        this.fecha_inicio=año+'-'+mes+'-01';
        this.fecha_actual=año+'-'+mes+'-'+dia;
    }

    opcionDescuentoPorGrupo(){
        this.grupo=true;
        this.subgrupo=false;
        this.linea=false;
        this.marcas = false;
        this.prod = false;
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;

        this.getField('usuario').setValue(this.codigo_usuario);

        this.getField('nit').setVisible(true);
        this.getField('nombre').setVisible(true);
        this.getField('nombre').setOnChange(this.ocualtarCampos);
        this.getField('consultar').setVisible(true);
        this.getField('consultar').setClick(this.traerdescuentos);
        this.getField('desde').setOnChange(this.validarFechas);
        this.getField('hasta').setOnChange(this.validarFechas);
        this.getField('aceptar').setClick(this.validarunico);
        this.getField('cancelar').setClick(()=>{this.limpiar(); this.getField('agregar').setVisible(true);});
        this.getField('agregar').setClick(this.mostrarCampos);
        this.getField('cancelar1').setClick(()=>{this.getField('modal').handleClose()});
        this.getField('aceptar1').setClick(this.modificardescuento);

    }

    opcionDescuentoPorSubGrupo(){
        this.subgrupo=true;
        this.grupo=false;
        this.linea=false;
        this.marcas = false;
        this.prod = false;
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;

        this.getField('usuario').setValue(this.codigo_usuario);

        this.getField('nit').setVisible(true);
        this.getField('nombre').setVisible(true);
        this.getField('nombre').setOnChange(this.ocualtarCampos);
        this.getField('consultar').setVisible(true);
        this.getField('consultar').setClick(this.traerdescuentos);
        this.getField('desde').setOnChange(this.validarFechas);
        this.getField('hasta').setOnChange(this.validarFechas);
        this.getField('aceptar').setClick(this.validarunico);
        this.getField('cancelar').setClick(()=>{this.limpiar(); this.getField('agregar').setVisible(true);});
        this.getField('agregar').setClick(this.mostrarCampos);
        this.getField('cancelar1').setClick(()=>{this.getField('modal').handleClose()});
        this.getField('aceptar1').setClick(this.modificardescuento);

    }

    opcionDescuentoPorLineas(){
        this.subgrupo=false;
        this.grupo=false;
        this.linea=true;
        this.marcas = false;
        this.prod = false;
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;

        this.getField('usuario').setValue(this.codigo_usuario);

        this.getField('nit').setVisible(true);
        this.getField('nombre').setVisible(true);
        this.getField('nombre').setOnChange(this.ocualtarCampos);
        this.getField('consultar').setVisible(true);
        this.getField('consultar').setClick(this.traerdescuentos);
        this.getField('desde').setOnChange(this.validarFechas);
        this.getField('hasta').setOnChange(this.validarFechas);
        this.getField('aceptar').setClick(this.validarunico);
        this.getField('cancelar').setClick(()=>{this.limpiar(); this.getField('agregar').setVisible(true);});
        this.getField('agregar').setClick(this.mostrarCampos);
        this.getField('cancelar1').setClick(()=>{this.getField('modal').handleClose()});
        this.getField('aceptar1').setClick(this.modificardescuento);

    }

    opcionDescuentoPorMarcas() {
        this.subgrupo = false;
        this.grupo = false;
        this.linea = false;
        this.marcas = true;
        this.prod = false;
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;

        this.getField('usuario').setValue(this.codigo_usuario);

        this.getField('nit').setVisible(true);
        this.getField('nombre').setVisible(true);
        this.getField('nombre').setOnChange(this.ocualtarCampos);
        this.getField('consultar').setVisible(true);
        this.getField('consultar').setClick(this.traerdescuentos);
        this.getField('desde').setOnChange(this.validarFechas);
        this.getField('hasta').setOnChange(this.validarFechas);
        this.getField('aceptar').setClick(this.validarunico);
        this.getField('cancelar').setClick(() => { this.limpiar(); this.getField('agregar').setVisible(true); });
        this.getField('agregar').setClick(this.mostrarCampos);
        this.getField('cancelar1').setClick(() => { this.getField('modal').handleClose() });
        this.getField('aceptar1').setClick(this.modificardescuento);
    }

    opcionDescuentoPorProd() {
        this.subgrupo = false;
        this.grupo = false;
        this.linea = false;
        this.marcas = false;
        this.prod = true;
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;

        this.getField('usuario').setValue(this.codigo_usuario);

        this.getField('nit').setVisible(true);
        this.getField('nombre').setVisible(true);
        this.getField('nombre').setOnChange(this.ocualtarCampos);
        this.getField('consultar').setVisible(true);
        this.getField('consultar').setClick(this.traerdescuentos);
        this.getField('desde').setOnChange(this.validarFechas);
        this.getField('hasta').setOnChange(this.validarFechas);
        this.getField('aceptar').setClick(this.validarunico);
        this.getField('cancelar').setClick(() => { this.limpiar(); this.getField('agregar').setVisible(true); });
        this.getField('agregar').setClick(this.mostrarCampos);
        this.getField('cancelar1').setClick(() => { this.getField('modal').handleClose() });
        this.getField('aceptar1').setClick(this.modificardescuento);
    }

    traerdescuentos(){
        if(this.getField('nit').valid() && this.getField('nombre').valid()){
            let datos={ datos: {
                terceros_id:this.getField('terceros_id').getValue()
            }};
            if(this.grupo){
                this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: 'traerdescuentos', operacion_tipo: 'consulta' };
            }else if(this.subgrupo){
                this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: 'traerdescuentosubgrupos', operacion_tipo: 'consulta' };
            }else if(this.linea){
                this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: 'traerdescuentoslineas', operacion_tipo: 'consulta' };
            } else if (this.marcas) {
                this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: 'traerdescuentosmarcas', operacion_tipo: 'consulta' };
            }else if (this.prod) {
                this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: 'traerdescuentosproductos', operacion_tipo: 'consulta' };
            }
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerDescuento,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }

    successTraerDescuento(data){
        if (data.estado_p === 200 )
        {
            if(this.grupo){
                this.getField("rejilla").toggle(true);
                this.gridOptionsComponentes["rowData"] = data.data;
                let configCell = new Map();
                configCell.set('editar', { cellRenderer: this.setButtonEdit, sortable: false, filter: false, field: 'editar' });
                configCell.set('eliminar', { cellRenderer: this.setButtonDelete, sortable: false, filter: false, field: 'eliminar' });
    
                this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);
            }else if (this.subgrupo){
                this.getField("rejilla1").toggle(true);
                this.gridOptionsComponentes1["rowData"] = data.data;
                let configCell = new Map();
                configCell.set('editar', { cellRenderer: this.setButtonEdit, sortable: false, filter: false, field: 'editar' });
                configCell.set('eliminar', { cellRenderer: this.setButtonDelete, sortable: false, filter: false, field: 'eliminar' });
    
                this.getField('rejilla1').initData(this.gridOptionsComponentes1,configCell);
            }else if(this.linea){
                this.getField("rejilla2").toggle(true);
                this.gridOptionsComponentes2["rowData"] = data.data;
                let configCell = new Map();
                configCell.set('editar', { cellRenderer: this.setButtonEdit, sortable: false, filter: false, field: 'editar' });
                configCell.set('eliminar', { cellRenderer: this.setButtonDelete, sortable: false, filter: false, field: 'eliminar' });
    
                this.getField('rejilla2').initData(this.gridOptionsComponentes2,configCell);
            } else if (this.marcas) {
                this.getField("rejilla3").toggle(true);
                this.gridOptionsComponentes3["rowData"] = data.data;
                let configCell = new Map();
                configCell.set('editar', { cellRenderer: this.setButtonEdit, sortable: false, filter: false, field: 'editar' });
                configCell.set('eliminar', { cellRenderer: this.setButtonDelete, sortable: false, filter: false, field: 'eliminar' });

                this.getField('rejilla3').initData(this.gridOptionsComponentes3, configCell);
            } else if (this.prod) {
                this.getField("rejilla4").toggle(true);
                this.gridOptionsComponentes4["rowData"] = data.data;
                let configCell = new Map();
                configCell.set('editar', { cellRenderer: this.setButtonEdit, sortable: false, filter: false, field: 'editar' });
                configCell.set('eliminar', { cellRenderer: this.setButtonDelete, sortable: false, filter: false, field: 'eliminar' });

                this.getField('rejilla4').initData(this.gridOptionsComponentes4, configCell);
            }
            this.getField('agregar').setVisible(true);
            this.getField('consultar').setDisabled(true);

        }else {
            if(data.estado_p===404) {        
                this.alertGeneral.toggle(true, 'No hay datos.', 'error'); 
                this.getField('consultar').setDisabled(true);

                this.gridOptionsComponentes["rowData"] = [];
                this.gridOptionsComponentes1["rowData"] = [];
                this.gridOptionsComponentes2["rowData"] = [];
                this.gridOptionsComponentes3["rowData"] = [];
                this.gridOptionsComponentes4["rowData"] = [];
                let configCell = new Map();
                this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);
                this.getField("rejilla").toggle(false);
                this.getField('rejilla1').initData(this.gridOptionsComponentes1,configCell);
                this.getField("rejilla1").toggle(false);
                this.getField('rejilla2').initData(this.gridOptionsComponentes2,configCell);
                this.getField("rejilla2").toggle(false);
                this.getField('rejilla3').initData(this.gridOptionsComponentes3, configCell);
                this.getField("rejilla3").toggle(false);
                this.getField('rejilla4').initData(this.gridOptionsComponentes4, configCell);
                this.getField("rejilla4").toggle(false);
                this.mostrarCampos();
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        } 
    }

    mostrarCampos(){
        this.getField('hasta').setValue(this.fecha_actual);
        this.getField('desde').setValue(this.fecha_actual);
        if(this.grupo){
            this.getField('codigo_grupo').setVisible(true);
            this.getField('nombre_grupo').setVisible(true);
            this.getField('nombre_grupo').setValue("");
        }else if(this.subgrupo){
            this.getField('codigo_subgrupo').setVisible(true);
            this.getField('nombre_subgrupo').setVisible(true);
            this.getField('nombre_subgrupo').setValue("");
        }else if(this.linea){
            this.getField('codigo_linea').setVisible(true);
            this.getField('nombre_linea').setVisible(true);
            this.getField('nombre_linea').setValue("");
        } else if (this.marcas) {
            this.getField('codigo_marcas').setVisible(true);
            this.getField('nombre_marcas').setVisible(true);
            this.getField('nombre_marcas').setValue("");
        } else if (this.prod) {
            this.getField('codigo_producto').setVisible(true);
            this.getField('nombre_productos').setVisible(true);
            this.getField('nombre_productos').setValue("");
        }
        this.getField('desde').setVisible(true);
        this.getField('hasta').setVisible(true);
        this.getField('porcentaje').setVisible(true);
        this.getField('porcentaje').setValue("");
        this.getField('estadosactivos_id').setVisible(true);
        this.getField('estadosactivos_id').setValue("");
        this.getField('aceptar').setVisible(true);
        this.getField('cancelar').setVisible(true);
        this.getField('agregar').setVisible(false);
    }

    validarFechas() {
        let fecha_desde = new Date(this.getField("desde").getValue());
        let fecha_hasta = new Date(this.getField("hasta").getValue());
        
        const fecha_minima = new Date('2025-01-01');
        const fecha_maxima = new Date(fecha_desde);
        fecha_maxima.setFullYear(fecha_maxima.getFullYear() + 2);
    
        // Resetear errores
        this.getField("desde").setError(false, "");
        this.getField("hasta").setError(false, "");
    
        // Validar fecha desde
        if (fecha_desde < fecha_minima) {
            this.getField("desde").setError(true, "La fecha desde no puede ser menor a 2025-01-01.");
            return false;
        }
    
        // Validar fecha hasta
        if (fecha_hasta > fecha_maxima) {
            this.getField("hasta").setError(true, "La fecha hasta no puede ser mayor a dos años de la fecha desde.");
            return false;
        }
    
        // Validar que fecha desde no sea mayor que fecha hasta
        if (fecha_desde > fecha_hasta) {
            this.getField("hasta").setError(true, "La fecha hasta no puede ser menor a la fecha desde.");
            return false;
        }
    
        return true; 
    }

    validarunico(){
        if(this.grupo){
            if(this.getField('codigo_grupo').valid() && this.getField('nombre_grupo').valid()
            && this.getField('porcentaje').valid() && this.getField('estadosactivos_id').valid()){
                this.getField('aceptar').setDisabled(true);
    
                let datos={ datos: {
                    terceros_id:this.getField('terceros_id').getValue(),
                    grupos_id:this.getField('grupos_id').getValue()
                }};
                this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: 'validarunico', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successvalidarunico,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
            }
        }else if(this.subgrupo){
            if(this.getField('codigo_subgrupo').valid() && this.getField('nombre_subgrupo').valid()
            && this.getField('porcentaje').valid() && this.getField('estadosactivos_id').valid()){
                this.getField('aceptar').setDisabled(true);
    
                let datos={ datos: {
                    terceros_id:this.getField('terceros_id').getValue(),
                    subgrupos_id:this.getField('subgrupos_id').getValue()
                }};
                this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: 'validarunicogrupos', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successvalidarunico,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
            }
        }else if(this.linea){
            if(this.getField('codigo_linea').valid() && this.getField('nombre_linea').valid()
            && this.getField('porcentaje').valid() && this.getField('estadosactivos_id').valid()){
                this.getField('aceptar').setDisabled(true);
    
                let datos={ datos: {
                    terceros_id:this.getField('terceros_id').getValue(),
                    lineas_id:this.getField('lineas_id').getValue()
                }};
                this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: 'validarunicolineas', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successvalidarunico,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
            }
        } else if (this.marcas) {
            if (this.getField('codigo_marcas').valid() && this.getField('nombre_marcas').valid()
                && this.getField('porcentaje').valid() && this.getField('estadosactivos_id').valid()) {
                this.getField('aceptar').setDisabled(true);

                let datos = {
                    datos: {
                        terceros_id: this.getField('terceros_id').getValue(),
                        marcas_id: this.getField('marcas_id').getValue()
                    }
                };
                this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: 'validarunicomarcas', operacion_tipo: 'consulta' };
                this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method: 'GET',
                        body: datos,
                        success: this.successvalidarunico,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                    });
            }
        } else if (this.prod) {
            if (this.getField('codigo_producto').valid() && this.getField('nombre_productos').valid()
                && this.getField('porcentaje').valid() && this.getField('estadosactivos_id').valid()) {
                this.getField('aceptar').setDisabled(true);

                let datos = {
                    datos: {
                        terceros_id: this.getField('terceros_id').getValue(),
                        productos_id: this.getField('productos_id').getValue()
                    }
                };
                this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: 'validarunicoproductos', operacion_tipo: 'consulta' };
                this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method: 'GET',
                        body: datos,
                        success: this.successvalidarunico,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                    });
            }
        }
    }

    successvalidarunico(data){
        if (data.estado_p === 200 )
        {
            this.getField('codigo_grupo').setValue("");
            this.getField('nombre_grupo').setValue("");
            this.getField('codigo_subgrupo').setValue("");
            this.getField('nombre_subgrupo').setValue("");
            this.getField('codigo_linea').setValue("");
            this.getField('nombre_linea').setValue("");
            this.getField('codigo_marcas').setValue("");
            this.getField('nombre_marcas').setValue("");
            this.getField('codigo_producto').setValue("");
            this.getField('nombre_productos').setValue("");
            this.getField('porcentaje').setValue("");
            this.getField('estadosactivos_id').setValue('');
            this.alertGeneral.toggle(true, 'El descuento ya existe.', 'error'); 
            this.getField('aceptar').setDisabled(false);

        }else {
            if(data.estado_p===404) {
                this.guardardescuentos();
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        } 
    }

    guardardescuentos(){
        if(this.grupo){
            let datos={ datos: {
                terceros_id:this.getField('terceros_id').getValue(),
                grupos_id:this.getField('grupos_id').getValue(),
                subgrupos_id:1,
                lineas_id:1,
                marcas_id:1,
                productos_id: 1,
                desde:this.getField('desde').getValue(),
                hasta:this.getField('hasta').getValue(),
                porcentaje:this.getField('porcentaje').getValue(),
                usuario:this.getField('usuario').getValue(),
                estadosactivos_id:this.getField('estadosactivos_id').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: '5', operacion_tipo: 'crear' };

            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successGuardardescuentos,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }else if(this.subgrupo){
            let datos={ datos: {
                terceros_id:this.getField('terceros_id').getValue(),
                subgrupos_id:this.getField('subgrupos_id').getValue(),
                grupos_id:1,
                lineas_id:1,
                marcas_id:1,
                productos_id: 1,
                desde:this.getField('desde').getValue(),
                hasta:this.getField('hasta').getValue(),
                porcentaje:this.getField('porcentaje').getValue(),
                usuario:this.getField('usuario').getValue(),
                estadosactivos_id:this.getField('estadosactivos_id').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: '5_1', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successGuardardescuentos,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }else if(this.linea){
            let datos={ datos: {
                terceros_id:this.getField('terceros_id').getValue(),
                lineas_id:this.getField('lineas_id').getValue(),
                grupos_id:1,
                subgrupos_id:1,
                marcas_id:1,
                productos_id: 1,
                desde:this.getField('desde').getValue(),
                hasta:this.getField('hasta').getValue(),
                porcentaje:this.getField('porcentaje').getValue(),
                usuario:this.getField('usuario').getValue(),
                estadosactivos_id:this.getField('estadosactivos_id').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: '5_2', operacion_tipo: 'crear' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'POST',
                    body: datos,
                    success: this.successGuardardescuentos,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
        } else if (this.marcas) {
            let datos = {
                datos: {
                    terceros_id: this.getField('terceros_id').getValue(),
                    marcas_id: this.getField('marcas_id').getValue(),
                    grupos_id: 1,
                    subgrupos_id: 1,
                    lineas_id: 1,
                    productos_id: 1,
                    desde: this.getField('desde').getValue(),
                    hasta: this.getField('hasta').getValue(),
                    porcentaje: this.getField('porcentaje').getValue(),
                    usuario: this.getField('usuario').getValue(),
                    estadosactivos_id: this.getField('estadosactivos_id').getValue()
                }
            };
            this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: '5_3', operacion_tipo: 'crear' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'POST',
                    body: datos,
                    success: this.successGuardardescuentos,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
        } else if (this.prod) {
            let datos = {
                datos: {
                    terceros_id: this.getField('terceros_id').getValue(),
                    productos_id: this.getField('productos_id').getValue(),
                    marcas_id: 1,
                    grupos_id: 1,
                    subgrupos_id: 1,
                    lineas_id: 1,
                    desde: this.getField('desde').getValue(),
                    hasta: this.getField('hasta').getValue(),
                    porcentaje: this.getField('porcentaje').getValue(),
                    usuario: this.getField('usuario').getValue(),
                    estadosactivos_id: this.getField('estadosactivos_id').getValue()
                }
            };
            this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: '5_4', operacion_tipo: 'crear' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'POST',
                    body: datos,
                    success: this.successGuardardescuentos,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
        }
    }

    successGuardardescuentos(data){
        this.getField('aceptar').setDisabled(false);

        if (data.estado_p === 200 )
        {
            this.traerdescuentos();
            this.limpiar();
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error'); 
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        } 
    }

    limpiar(){
        this.getField('hasta').setValue(this.fecha_actual);
        this.getField('desde').setValue(this.fecha_actual);
        this.getField('codigo_grupo').setVisible(false);
        this.getField('nombre_grupo').setVisible(false);
        this.getField('codigo_grupo').setValue("");
        this.getField('nombre_grupo').setValue("");
        this.getField('codigo_grupo').setError(false,"");
        this.getField('nombre_grupo').setError(false,"");
        this.getField('codigo_subgrupo').setVisible(false);
        this.getField('nombre_subgrupo').setVisible(false);
        this.getField('codigo_subgrupo').setValue("");
        this.getField('nombre_subgrupo').setValue("");
        this.getField('codigo_subgrupo').setError(false,"");
        this.getField('nombre_subgrupo').setError(false,"");
        this.getField('codigo_linea').setVisible(false);
        this.getField('nombre_linea').setVisible(false);
        this.getField('codigo_linea').setValue("");
        this.getField('nombre_linea').setValue("");
        this.getField('codigo_linea').setError(false,"");
        this.getField('nombre_linea').setError(false,"");
        this.getField('codigo_marcas').setVisible(false);
        this.getField('nombre_marcas').setVisible(false);
        this.getField('codigo_marcas').setValue("");
        this.getField('nombre_marcas').setValue("");
        this.getField('codigo_marcas').setError(false, "");
        this.getField('nombre_marcas').setError(false, "");
        this.getField('codigo_producto').setVisible(false);
        this.getField('nombre_productos').setVisible(false);
        this.getField('codigo_producto').setValue("");
        this.getField('nombre_productos').setValue("");
        this.getField('codigo_producto').setError(false, "");
        this.getField('nombre_productos').setError(false, "");
        this.getField('desde').setVisible(false);
        this.getField('hasta').setVisible(false);
        this.getField('porcentaje').setVisible(false);
        this.getField('porcentaje').setValue("");
        this.getField('porcentaje').setError(false,"");
        this.getField('estadosactivos_id').setVisible(false);
        this.getField('estadosactivos_id').setValue('');
        this.getField('aceptar').setVisible(false);
        this.getField('aceptar').setDisabled(false);
        this.getField('cancelar').setVisible(false);
    }
    
    ocualtarCampos(){
        this.limpiar();
        this.getField('agregar').setVisible(false);
        this.getField('consultar').setDisabled(false);
        this.gridOptionsComponentes["rowData"] = [];
        this.gridOptionsComponentes1["rowData"] = [];
        this.gridOptionsComponentes2["rowData"] = [];
        this.gridOptionsComponentes3["rowData"] = [];
        this.gridOptionsComponentes4["rowData"] = [];
        let configCell = new Map();
        this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);
        this.getField("rejilla").toggle(false);
        this.getField('rejilla1').initData(this.gridOptionsComponentes1,configCell);
        this.getField("rejilla1").toggle(false);
        this.getField('rejilla2').initData(this.gridOptionsComponentes2,configCell);
        this.getField("rejilla2").toggle(false);
        this.getField('rejilla3').initData(this.gridOptionsComponentes3, configCell);
        this.getField("rejilla3").toggle(false);
        this.getField('rejilla4').initData(this.gridOptionsComponentes4, configCell);
        this.getField("rejilla4").toggle(false);
        if(this.getField('nombre').getValue() !== ""){
            this.getField('nombre').setError(false,'');
        }

    }
    
    setButtonDelete(props) {
        if(this.grupo){
            this.data = props.data;
        }else if (this.subgrupo){
            this.data = props.data;
        }else if (this.linea){
            this.data = props.data;
        } else if (this.marcas) {
            this.data = props.data;
        } else if (this.prod) {
            this.data = props.data;
        }
        let button = document.createElement("input");
        button.onclick = () => this.confirmEliminarDescuento(this.data);
        button.setAttribute("id", 'button_delete_' + this.id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "eliminar");
        return this.createElementJaivana(button);
    }

    confirmEliminarDescuento(data) {
        this.ids = data.id;
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(() => this.agregarhistorico(data));
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("confirmModalCustom").setClickCancelDialog(() => {
            this.getField('confirmModalCustom').toggle(false);
        });
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("confirmModalCustom").setButtonConfirm('Confirmar');
        this.getField('confirmModalCustom').toggle(true);
    }

    agregarhistorico(data){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se esta generando la información... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        if(this.grupo){
            let datos = { datos: {
                terceros_id:this.getField('terceros_id').getValue(),
                lineas_id:1,
                grupos_id:data.grupos_id,
                subgrupos_id:1,
                marcas_id:1,
                productos_id:1,
                desde:data.desde,
                hasta:data.hasta,
                porcentaje:data.porcentaje,
                usuario:data.usuario,
                estadosactivos_id:data.estadosactivos_id
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: 'agregarhistoricogrupo', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successagregarhistorico,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }else if(this.subgrupo){
            let datos = { datos: {
                terceros_id:this.getField('terceros_id').getValue(),
                lineas_id:1,
                subgrupos_id:data.subgrupos_id,
                grupos_id:1,
                marcas_id:1,
                productos_id:1,
                desde:data.desde,
                hasta:data.hasta,
                porcentaje:data.porcentaje,
                usuario:data.usuario,
                estadosactivos_id:data.estadosactivos_id
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: 'agregarhistoricosubgrupo', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successagregarhistorico,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }else if(this.linea){
            let datos = { datos: {
                terceros_id:this.getField('terceros_id').getValue(),
                lineas_id:data.lineas_id,
                grupos_id:1,
                subgrupos_id:1,
                marcas_id:1,
                productos_id:1,
                desde:data.desde,
                hasta:data.hasta,
                porcentaje:data.porcentaje,
                usuario:data.usuario,
                estadosactivos_id:data.estadosactivos_id
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: 'agregarhistoricolineas', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successagregarhistorico,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        } else if (this.marcas) {
            let datos = { datos: {
                terceros_id:this.getField('terceros_id').getValue(),
                marcas_id:data.marcas_id,
                lineas_id:1,
                grupos_id:1,
                subgrupos_id:1,
                productos_id:1,
                desde:data.desde,
                hasta:data.hasta,
                porcentaje:data.porcentaje,
                usuario:data.usuario,
                estadosactivos_id:data.estadosactivos_id
            }};

            this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: 'agregarhistoricomarcas', operacion_tipo: 'crear' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'POST',
                    body: datos,
                    success: this.successagregarhistorico,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        } else if (this.prod) {
            let datos = { datos: {
                terceros_id:this.getField('terceros_id').getValue(),
                productos_id: data.productos_id,
                marcas_id:1,
                lineas_id:1,
                grupos_id:1,
                subgrupos_id:1,
                desde:data.desde,
                hasta:data.hasta,
                porcentaje:data.porcentaje,
                usuario:data.usuario,
                estadosactivos_id:data.estadosactivos_id
            }};

            this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: 'agregarhistoricoproductos', operacion_tipo: 'crear' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'POST',
                    body: datos,
                    success: this.successagregarhistorico,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }
    }

    successagregarhistorico(data){
        if (data.estado_p === 200)
        {
            this.eliminarDescuento();
        }else {
            this.getField('confirmModalCustom').toggle(false);
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }

    eliminarDescuento(){
        if(this.grupo){
            if(this.getField('nit').valid()&&
                this.getField('nombre').valid()&&
                this.getField('descuentos_grupos_id').valid()){
                let datos = { datos: {
                    descuentos_grupos_id:this.getField('descuentos_grupos_id').getValue()
                }};
                
                this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: '7', operacion_tipo: 'eliminar' };
                
                
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'DELETE',
                        body: datos,
                        success: this.successEliminarDescuento,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
            }else{
                this.alertGeneral.toggle(true, 'Error no se recuperaron registros al actualizar.', 'error');
            }
        }else if(this.subgrupo){
            if(this.getField('nit').valid()&&
                this.getField('nombre').valid()&&
                this.getField('descuentos_subgrupos_id').valid()){
                let datos = { datos: {
                    descuentos_subgrupos_id:this.getField('descuentos_subgrupos_id').getValue()
                }};
                
                this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: '7_1', operacion_tipo: 'eliminar' };
                
                
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'DELETE',
                        body: datos,
                        success: this.successEliminarDescuento,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
            }else{
                this.alertGeneral.toggle(true, 'Error no se recuperaron registros al actualizar.', 'error');
            }
        }else if(this.linea){
            if(this.getField('nit').valid()&&
                this.getField('nombre').valid()&&
                this.getField('descuentos_lineas_id').valid()){
                let datos = { datos: {
                    descuentos_lineas_id:this.getField('descuentos_lineas_id').getValue()
                }};
                
                this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: '7_2', operacion_tipo: 'eliminar' };
                
                
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'DELETE',
                        body: datos,
                        success: this.successEliminarDescuento,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
            }else{
                this.alertGeneral.toggle(true, 'Error no se recuperaron registros al actualizar.', 'error');
            }

        } else if (this.marcas) {
            if (this.getField('nit').valid() &&
                this.getField('nombre').valid() &&
                this.getField('descuentos_marcas_id').valid()) {
                let datos = {
                    datos: {
                        descuentos_marcas_id: this.getField('descuentos_marcas_id').getValue()
                    }
                };

                this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: '7_3', operacion_tipo: 'eliminar' };


                this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method: 'DELETE',
                        body: datos,
                        success: this.successEliminarDescuento,
                        error: this.error_,
                        general: this.generalFormatPmv
                    });
            } else {
                this.alertGeneral.toggle(true, 'Error no se recuperaron registros al actualizar.', 'error');
            }

        } else if (this.prod) {
            if (this.getField('nit').valid() &&
                this.getField('nombre').valid() &&
                this.getField('descuentos_productos_id').valid()) {
                let datos = {
                    datos: {
                        descuentos_productos_id: this.getField('descuentos_productos_id').getValue()
                    }
                };

                this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: '7_4', operacion_tipo: 'eliminar' };


                this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method: 'DELETE',
                        body: datos,
                        success: this.successEliminarDescuento,
                        error: this.error_,
                        general: this.generalFormatPmv
                    });
            } else {
                this.alertGeneral.toggle(true, 'Error no se recuperaron registros al actualizar.', 'error');
            }

        }
    }

    successEliminarDescuento(data) 
    {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200)
        {
            this.traerdescuentos();
        }
        else 
        {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }

    setButtonEdit(props) {
        let id = props.data.descuentos_grupos_id;
        let data = props.data;
        let button = document.createElement("input");
        button.onclick = () => this.abrirmodal(data);
        button.setAttribute("id", 'button_delete_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "editar");
        return this.createElementJaivana(button);
    }

    abrirmodal(data){
        if(this.grupo){
            this.idedit=data.descuentos_grupos_id;
        }else if(this.subgrupo){
            this.idedit=data.descuentos_subgrupos_id;
        }else if(this.linea){
            this.idedit=data.descuentos_lineas_id;
        } else if (this.marcas) {
            this.idedit = data.descuentos_marcas_id;
        } else if (this.prod) {
            this.idedit = data.descuentos_productos_id;
        }
        this.getField('modal').setVisible(true);
        this.getField('descuento_grupo').setVisible(true);
        this.getField('descuento_grupo').setValue(data.porcentaje);
        this.getField('aceptar1').setVisible(true);
        this.getField('cancelar1').setVisible(true);
    }

    modificardescuento(){
        if(this.getField('descuento_grupo').valid()){
            if(this.grupo){
                let datos={ datos: {
                    descuentos_grupos_id:this.idedit,
                    porcentaje:this.getField('descuento_grupo').getValue()
                }};
                this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: '6', operacion_tipo: 'modificar' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'PUT',
                        body: datos,
                        success: this.successmodificardescuento,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });                
            }else if(this.subgrupo){
                let datos={ datos: {
                    descuentos_subgrupos_id:this.idedit,
                    porcentaje:this.getField('descuento_grupo').getValue()
                }};
                this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: '6_1', operacion_tipo: 'modificar' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'PUT',
                        body: datos,
                        success: this.successmodificardescuento,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });     
            }else if(this.linea){
                let datos={ datos: {
                    descuentos_lineas_id:this.idedit,
                    porcentaje:this.getField('descuento_grupo').getValue()
                }};
                this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: '6_2', operacion_tipo: 'modificar' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'PUT',
                        body: datos,
                        success: this.successmodificardescuento,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });     

            } else if (this.marcas) {
                let datos = {
                    datos: {
                        descuentos_marcas_id: this.idedit,
                        porcentaje: this.getField('descuento_grupo').getValue()
                    }
                };
                this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: '6_3', operacion_tipo: 'modificar' };
                this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method: 'PUT',
                        body: datos,
                        success: this.successmodificardescuento,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                    });

            } else if (this.prod) {
                let datos = {
                    datos: {
                        descuentos_productos_id: this.idedit,
                        porcentaje: this.getField('descuento_grupo').getValue()
                    }
                };
                this.generalFormatPmv = { tipo_servicio: 'maes-tercerosdescuentogrupos', operacion: '6_4', operacion_tipo: 'modificar' };
                this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method: 'PUT',
                        body: datos,
                        success: this.successmodificardescuento,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                    });

            }
        }
    }

    successmodificardescuento(data){
        if (data.estado_p === 200 )
        {
            this.getField('modal').handleClose();
            this.traerdescuentos();
            this.limpiar();
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error'); 
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        } 
    }

}
FormJaivana.addController("maes-tercerosdescuentogrupos",CustomDescuentosPorGrupo);
export default CustomDescuentosPorGrupo
import { ThreeDRotationSharp } from '@mui/icons-material';
import FormJaivana from 'dashboard_jaivana_v1';

/**
 *
 * @author Juan Camilo Villa
 * @modified 07-01-2025
*/
/**
 * CustomVendingMaquinas
**/
class CustomVendingMaquinas extends FormJaivana.form {


   constructor(props) {

        super(props);
        this.initForm = this.initForm.bind(this);
        this.gridOptionsTbPrincipal = Object.assign({},this.gridOptions);
        this.arrayPrincipal = [];
        this.arrayPrincipalMaquines = [];

        this.getAllMachinesNoacloud = this.getAllMachinesNoacloud .bind(this);
        this.successGetAllMachinesNoacloud = this.successGetAllMachinesNoacloud.bind(this);
        
        this.getAllVendingMachines = this.getAllVendingMachines.bind(this);
        this.successGetAllVendingMachines = this.successGetAllVendingMachines.bind(this);
        
        this.updateRegister = this.updateRegister.bind(this);
        this.successUpdateMachines = this.successUpdateMachines.bind(this);
        
        this.setButtonModificar = this.setButtonModificar.bind(this);
        this.crearBotonTabla = this.crearBotonTabla.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.objectTabla = {nit: '', sede: 0, serial: ''};

    }

    initForm() {
       console.log('CustomVendingMaquinas,  @version: jdesk_1.01.0001, @author: Juan Camilo Villa');
       /* this.getAllMachinesNoacloud(); */
        this.getAllVendingMachines();
        this.getField('btn_manejo_maquinas_dispensaciones').setClick(this.getAllMachinesNoacloud);
        this.getField('modal_modificar_nit').setHandlerClose(this.closeModal);
        this.getField('btn_modal_modificar_nit').setClick(this.updateRegister);

        
    }

    /**
     * obtiene y actualizan todas las máquinas
     */
    getAllMachinesNoacloud(){
        let datos={ datos: {}};

        this.generalFormatPmv = { tipo_servicio: 'inte-maquinasvending', operacion: 'machines', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successGetAllMachinesNoacloud,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
                
        });
    }

    successGetAllMachinesNoacloud(data){
        if (data.estado_p === 200) {
            this.arrayPrincipalMaquines = data.data;
            this.getAllVendingMachines();
            /* this.updateRegister(); */
        }else{
            /**
             *       let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
             */
            if (data.hasOwnProperty('mensaje')) {
                let objectMessageError = data.mensaje;
                if (objectMessageError.includes('Access token expired')) {
                    this.alertGeneral.toggle(true, 'Error!!  Token Expirado', 'error');
                } else {

                    this.alertGeneral.toggle(true, 'Error obteniendo máquinas Noacloud', 'error');
                }

            }else{
                this.alertGeneral.toggle(true, 'Error obteniendo máquinas Noacloudddd', 'error');
            }
        }
    }

    /**
     * Trae toda la información de los clientes con sus respectivas máquinas asociadas
     */
    getAllVendingMachines(){
        let datos={ datos: {
        }};

        this.generalFormatPmv = { tipo_servicio: 'inte-maquinasvending', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successGetAllVendingMachines,
                error: this.error_,
                general: this.generalFormatPmv,
        });
    }



    successGetAllVendingMachines(data){
        if (data.estado_p === 200) {
            this.getField('tabla_maquinas').toggle(true);
            this.arrayPrincipal = data.data
            this.gridOptionsTbPrincipal['rowData'] = this.arrayPrincipal;
            let configCell = new Map();
            configCell.set('serial', {cellRenderer:function(props){return props.data.serial}, sortable: false, filter: false, field: 'Número Serial' });
            configCell.set('customes', {cellRenderer:function(props){return props.data.customes},sortable: false, filter: false, field: 'Cliente' });
            configCell.set('clientes_id', {cellRenderer:function(props){return props.data.clientes_id} ,sortable: false, filter: false, field: 'Cliente Id' });
            configCell.set('nombre_cliente', {cellRenderer:function(props){return props.data.nombre_cliente} ,sortable: false, filter: false, field: 'Nombre Cliente' });
            configCell.set('nit_cliente', {cellRenderer:function(props){return props.data.nit_cliente} ,sortable: false, filter: false, field: 'Nit Cliente' });
            configCell.set('sede', {cellRenderer:function(props){return props.data.sede} ,sortable: false, filter: false,});
            configCell.set('ultima_fecha_actualizacion', { cellRenderer:function(props){return props.data.ultima_fecha_actualizacion} ,sortable: false, filter: false, field: 'Última Fecha Actualización' });
            configCell.set('ultima_fecha_dispenscion', { cellRenderer:function(props){return props.data.ultima_fecha_dispenscion} ,sortable: false, filter: false, field: 'Última Fecha Dispensción' });
            configCell.set('ultimo_id_dispensacion', { cellRenderer:function(props){return props.data.ultimo_id_dispensacion} ,sortable: false, filter: false, field: 'Última Id Dispensción' });
            configCell.set('accion', { cellRenderer: this.setButtonModificar, headerName: 'Acción', width: 200, sortable: false, filter: false });
            

            this.getField('tabla_maquinas').initData(this.gridOptionsTbPrincipal, configCell);

        }else{
            this.gridOptionsTbPrincipal['rowData'] = [];
            this.getField('tabla_maquinas').toggle(false);
        }
    }



    /**
 * Establece la creación del botón en la tabla
 * @param {Contiene los valores de la fila seleccionada en la tabla} props 
 */
    setButtonModificar(props) {
        let fragment = document.createDocumentFragment();
        let buttonImage = this.crearBotonTabla(props.data, 'Modificar');
        fragment.appendChild(buttonImage); 
        return this.createElementJaivana(fragment)
        
    }

    crearBotonTabla(data, nombreBoton) {
        let button = document.createElement('input');
        button.setAttribute("class", "buttonStyleImg");
        button.onclick = () => { this.accionModificarNit(data) };
        button.setAttribute('value', nombreBoton);
        button.setAttribute('type', 'button');
        button.setAttribute("class", "buttonStyle2");
        button.setAttribute("id", `accionModificarNit_${data.id}`);
        return button;
    }

    accionModificarNit(data){
        this.objectTabla['nit'] = data.nit_cliente;
        this.objectTabla['sede'] = data.sede;
        this.objectTabla['serial'] = data.serial;
        this.getField('modal_modificar_nit').handleClickOpen();
    }
    
    closeModal(){
        this.objectTabla = {nit: '', sede: 0, serial:''};
        this.getField("modal_modificar_nit").handleCloseButton();
    }


    updateRegister(){
        if (this.getField('buscador_nit').valid() && this.getField('buscador_nit').valid() && this.objectTabla['serial'] !== '') {
            let datos={ datos: {
                nit: this.getField('buscador_nit').getValue(),
                sede: this.getField('sedeNit').getValue(),
                serial: this.objectTabla['serial'],
            }};

            this.generalFormatPmv = { tipo_servicio: 'inte-maquinasvending', operacion: '6_5', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successUpdateMachines,
                    error: this.error_,
                    general: this.generalFormatPmv,
            });
            
        } else {
            this.alertGeneral.toggle(true, 'No hay datos para actualizar' , 'error');
            
        }
    }

    successUpdateMachines(data){
        if (data.estado_p === 200) {
            this.getAllVendingMachines();
            this.getField("modal_modificar_nit").handleClose();
        }
    }
    




}
FormJaivana.addController('inte-maquinasvending',CustomVendingMaquinas);
export default CustomVendingMaquinas;
import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomConsultaExistenciasCostos
 * @author: Patricia Lopez Sanchez
 * @version: jdesk_1.01.0006
 **/

class CustomConsultaExistenciasCostos extends FormJaivana.form { 

    constructor(props) { 
        super(props) 
        this.initForm                                       = this.initForm.bind(this); // Esta es una función y es la primera que se ejecuta al abrir el formulario, es una función de Jaivaná, precarga todas las acciones ejecutadas desde custom en el formulario
        // Variables de tablas:
        this.gridOptionsPrecios                             = Object.assign({}, this.gridOptions);
        this.configuracionTablaBodega1                      = Object.assign({}, this.gridOptions);
        this.configuracionTablaBodega2                      = Object.assign({}, this.gridOptions);
        
        // Variables Globales:
        this.respuestaTraerBodegas                          = []; // Se declara esta variable de tipo array que va a contener la respuesta del servicio "bodegas_mostrar_web"
        this.respuestaExistenciasWms                        = []; // Se declara esta variable de tipo array que va a contener la respuesta del servicio "articulo-existencias-wms"
        this.arrayFinal                                     = []; // Esta variable de tipo array que va a contener el data que se va a dibujar en las dos tablas.
        
        // Funciones:
        this.tbPrecios                                      = this.tbPrecios.bind(this);
        this.traerBodegas                                   = this.traerBodegas.bind(this);
        this.successTraerBodegas                            = this.successTraerBodegas.bind(this);
        this.articuloExistenciasWms                         = this.articuloExistenciasWms.bind(this);
        this.successArticuloExistenciasWms                  = this.successArticuloExistenciasWms.bind(this);
        this.articuloCostoSaldoTodos                        = this.articuloCostoSaldoTodos.bind(this);
        this.successArticuloCostoSaldoTodos                 = this.successArticuloCostoSaldoTodos.bind(this);
        this.setButtonCostoPro                              = this.setButtonCostoPro.bind(this);
        this.setButtonSaldo                                 = this.setButtonSaldo.bind(this);
        this.dibujarTbBodegas                               = this.dibujarTbBodegas.bind(this);
        this.ver_margen                                     = this.ver_margen.bind(this);
        this.successver_margen                              = this.successver_margen.bind(this);
    }

    /**
     * Dentro de esta función se escriben todos los métodos, funciones, etc que se ejuctaran al abrir el formulario
     * Funcion por dónde iniciamos el componente, "se dispara cuando el formulario se abre"
     */
    initForm() {
        console.log('Consulta existencias y costos, @version: 1.01.0006, @author: Patricia Lopez Sanchez');

        this.getField('precio3').setOnChange(this.tbPrecios);
        this.ver_margen();
    }

    ver_margen(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'vermargen', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body:datos,
                success: this.successver_margen,
                error: this.error_,
                general: this.generalFormatPmv
            }
        )
    }

    successver_margen(data){
        if (data.estado_p === 200 && data.data.length >= 1) {
            this.verMargen = data.data[0].ver_margen;
            //console.log('this.verMargen:\n',this.verMargen);
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    dibujarTbBodegas(arrayGraficar){
        //console.log('arrayGraficar:\n',arrayGraficar);
        //console.log('resultS:\n',resultS);
        if (this.respuestaExistenciasWms.length >= 1 && arrayGraficar.length >= 1) {
            let arrayValoresExistenciasWms = Object.entries(this.respuestaExistenciasWms[0]); // Object.entries sirve para que crea un nuevo arreglo de arreglos, en donde cada valor del arreglo es otro arreglo con dos valores. El primer valor es la clave y el segundo el valor(Fuente: https://parzibyte.me/blog/2019/08/16/objeto-arreglo-javascript-conversion/).
            //console.log('arrayValoresExistenciasWms:\n',arrayValoresExistenciasWms);
            for (let i = 0; i < arrayGraficar.length; i++) {
                let objetoFinal = {};
                arrayValoresExistenciasWms.forEach((item)=>{
                    //console.log('Lo que necesito ver:\n',item[0]);
                    if (item[0].replace("saldo", "") === arrayGraficar[i].codigo_bodega) {
                        arrayGraficar[i].saldo = item[1] === undefined || item[1] === null ? 0 : item[1];
                    }
                });
                if (Object.keys(objetoFinal).length >= 1) {
                    arrayGraficar.push(objetoFinal);
                }
            }
            //console.log('Este es el arrayFinal 2:\n',arrayGraficar);
            if(arrayGraficar.length >= 1){
                let configCell = new Map();
                let dataTabla1 = [];
                let dataTabla2 = [];
                arrayGraficar.forEach((item, key) => {
                    if(key <= 24){
                        dataTabla1.push(item);
                    }else{
                        dataTabla2.push(item);
                    }
                });
                configCell.set('codigo_bodega', {width: 70});
                configCell.set('costo_pro', {cellRenderer: this.setButtonCostoPro, width: 100, headerName:'Costo'});
                configCell.set('saldo', {cellRenderer: this.setButtonSaldo, width: 100});
                this.configuracionTablaBodega1.rowData = dataTabla1;
                this.configuracionTablaBodega2.rowData = dataTabla2;
                this.configuracionTablaBodega1.paginationPageSize = 25;
                this.configuracionTablaBodega2.paginationPageSize = 25;
                this.configuracionTablaBodega1.pagination = false;
                this.getField('tbBodegas1').initData(this.configuracionTablaBodega1,configCell);
                if(dataTabla2.length > 0){
                    this.getField('tbBodegas2').initData(this.configuracionTablaBodega2,configCell);
                }else{
                    this.getField('tbBodegas2').toggle(false);
                }
            }else{
                this.getField('tbBodegas1').toggle(false);
                this.getField('tbBodegas2').toggle(false);
            }
        }
    }

    setButtonCostoPro(props){
        let costo_pro = props.data.costo_pro;
        let div = document.createElement('div');
        div.setAttribute("style", "height: 100%;width: 100%;padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;text-align: right;");
        div.textContent = "".formatoPrecio(costo_pro);
        return this.createElementJaivana(div);
    }
    
    setButtonSaldo(props){
        let saldo = props.data.saldo;
        let div = document.createElement('div');
        div.setAttribute("style", "height: 100%;width: 100%;padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;text-align: right;");
        div.textContent = "".formatoPrecio(saldo);
        return this.createElementJaivana(div);
    }
    
    articuloCostoSaldoTodos(){
        if (this.getField('codigo').valid()) {
            let datos={ datos: {
                codigo_articulo: this.getField('codigo').getValue(),
                sucursal: JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal
            }};
            this.generalFormatPmv = { tipo_servicio: 'most-articulocostosaldo', operacion: 'todos', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body:datos,
                    success: this.successArticuloCostoSaldoTodos,
                    error: this.error_,
                    general: this.generalFormatPmv
                }
            )
        }
    }

    successArticuloCostoSaldoTodos(data){
        //console.log('data.data successArticuloCostoSaldoTodos:\n', data.data);
        //console.log('data.data[0] successArticuloCostoSaldoTodos:\n', data.data[0]);

        let arrayValoresArtCostSald = Object.entries(data.data[0]); 
        //console.log('arrayValoresArtCostSald:\n', arrayValoresArtCostSald);

        if (this.verMargen !== undefined && this.verMargen !== '' && this.verMargen !== null) {
            if (data.estado_p === 200 && data.data.length >= 1 && this.respuestaTraerBodegas.length >= 1) {
                
                let costoGeneral = null;
                let tieneCostoGeneral = false;

                // Verificamos si existe solo 'costo_pro'
                arrayValoresArtCostSald.forEach((item) => {
                    if (item[0] === "costo_pro") {
                        costoGeneral = item[1] === undefined || item[1] === null ? 0 : item[1];
                        tieneCostoGeneral = true;
                    }
                });

                if(tieneCostoGeneral){
                    for (let i = 0; i < this.respuestaTraerBodegas.length; i++) {
                        let objetoFinal = {};
                        let en1 = false;
                        let en2 = false;
    
                        arrayValoresArtCostSald.forEach((item) => {
                            if (item[0].replace("costo_pro", "") === this.respuestaTraerBodegas[i].codigo_bodega) {
                                objetoFinal['costo_pro'] = item[1] === undefined || item[1] === null ? 0 : item[1];
                                en1 = true;
                            }
                            if (item[0].replace("saldo", "") === this.respuestaTraerBodegas[i].codigo_bodega) {
                                objetoFinal['saldo'] = item[1] === undefined || item[1] === null ? 0 : item[1];
                                en2 = true;
                            }
                        });
    
                        // Si no se encontró un costo específico para la bodega, usamos el costo general
                        if (!en1 && tieneCostoGeneral) {
                            objetoFinal['costo_pro'] = costoGeneral;
                            en1 = true;
                        }
    
                        // Si no se encontró saldo para la bodega, se asigna 0
                        if (!en2) {
                            objetoFinal['saldo'] = 0;
                            en2 = true;
                        }
    
                        if (en1 || en2) {
                            if (this.verMargen === 'N') {
                                objetoFinal['costo_pro'] = 0;
                            }
                            objetoFinal['codigo_bodega'] = this.respuestaTraerBodegas[i].codigo_bodega;
                            objetoFinal['nombre'] = this.respuestaTraerBodegas[i].nombre;
                            this.arrayFinal.push(objetoFinal);
                        }
                    }
                }else{
                    let arrayValoresArtCostSald = Object.entries(data.data[0]); // Object.entries sirve para que crea un nuevo arreglo de arreglos, en donde cada valor del arreglo es otro arreglo con dos valores. El primer valor es la clave y el segundo el valor(Fuente: https://parzibyte.me/blog/2019/08/16/objeto-arreglo-javascript-conversion/).

                    for (let i = 0; i < this.respuestaTraerBodegas.length; i++) {
                        let objetoFinal = {};
                        let en1 = false;
                        let en2 = false;
                        arrayValoresArtCostSald.forEach((item)=>{
                            if (item[0].replace("costo_pro_", "") === this.respuestaTraerBodegas[i].codigo_bodega) {
                                objetoFinal['costo_pro']=item[1] === undefined || item[1] === null ? 0 : item[1];
                                en1=true;
                            }
                            if(item[0].replace("saldo", "") === this.respuestaTraerBodegas[i].codigo_bodega){
                                objetoFinal['saldo']=item[1] === undefined || item[1] === null ? 0 : item[1];
                                en2 = true;
                            }
                            if (en1 && en2) {
                                if (this.verMargen === 'N') {
                                    objetoFinal['costo_pro']=0;
                                }
                                objetoFinal['codigo_bodega'] = this.respuestaTraerBodegas[i].codigo_bodega;
                                objetoFinal['nombre'] = this.respuestaTraerBodegas[i].nombre;
                                this.arrayFinal.push(objetoFinal);
                                en1=false;
                            }
                        });
                    }
                }

                //console.log("Array Final ===> ", this.arrayFinal);
                this.dibujarTbBodegas(this.arrayFinal);
            }
        } else {
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `Estimado usuario la variable ver_margen no existe en sus datos`);
            this.getField('confirmModalCustom').setClickDialog(() => {
                this.getField('codigo').setValue('');
                this.getField('precio3').setValue('');
                this.getField('nombre').setValue('');
                this.getField('confirmModalCustom').toggle(false);
            });
            this.getField("confirmModalCustom").setVisibleCancel(true);
            this.getField("confirmModalCustom").setButtonConfirm(`Ok`);
            this.getField('confirmModalCustom').toggle(true);
        }
    }

    articuloExistenciasWms(){
        if (this.getField('codigo').valid()) {
            let datos={ datos: {
                codigo: this.getField('codigo').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'traerarticuloswmscantidades', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body:datos,
                    success: this.successArticuloExistenciasWms,
                    error: this.error_,
                    general: this.generalFormatPmv
                }
            )
        }
    }

    successArticuloExistenciasWms(data){
        //console.log('Data successArticuloExistenciasWms:\n',data);
        if (data.estado_p === 200 && data.data.length >= 1) {
            this.respuestaExistenciasWms = data.data;
            //console.log('this.respuestaExistenciasWms:\n',this.respuestaExistenciasWms);
            this.articuloCostoSaldoTodos();
        }
    }
    
    traerBodegas(){
        let datos={ datos: {}};

        this.generalFormatPmv = { tipo_servicio: 'most-bodegas', operacion: 'webtodos', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body:datos,
                success: this.successTraerBodegas,
                error: this.error_,
                general: this.generalFormatPmv
            }
        )
    }

    successTraerBodegas(data){
        //console.log('Este es el data de successTraerBodegas:\n',data);
        if(data.estado_p === 200){
            this.respuestaTraerBodegas = data.data;
            //console.log('this.respuestaTraerBodegas:\n',this.respuestaTraerBodegas);
            this.articuloExistenciasWms();
        }
    }
    
    tbPrecios(){
        this.arrayFinal = [];
        this.respuestaTraerBodegas = [];
        this.respuestaExistenciasWms = [];
        this.configuracionTablaBodega1.rowData = [];
        this.getField('tbBodegas1').initData(this.configuracionTablaBodega1);
        this.getField('tbBodegas1').toggle(false);
        this.configuracionTablaBodega2.rowData = [];
        this.getField('tbBodegas2').initData(this.configuracionTablaBodega2);
        this.getField('tbBodegas2').toggle(false);
        if(this.getField('precio3').getValue() !== ''){
            let data = {};
            data.precio1 = this.getField('precio1').getValue();
            data.precio2 = this.getField('precio2').getValue();
            data.precio3 = this.getField('precio3').getValue();
            //console.log(data)
            let dataRow = [];
            dataRow.push(data);
            let configCell = new Map();
            configCell.set('precio1',{cellRenderer: function(props){return "".formatoPrecio(props.data.precio1)}});
            configCell.set('precio2',{cellRenderer: function(props){return "".formatoPrecio(props.data.precio2)}});
            configCell.set('precio3',{cellRenderer: function(props){return "".formatoPrecio(props.data.precio3)}});
            this.gridOptionsPrecios.rowData = dataRow;
            this.gridOptionsPrecios.pagination = false;
            this.gridOptionsPrecios.suppressPaginationPanel=true;
            this.gridOptionsPrecios['onSelectionChanged'] = this.nada;
            this.getField('tbPrecios').initData(this.gridOptionsPrecios,configCell);
            this.traerBodegas();
        }else{
            this.getField('tbPrecios').toggle(false);
        }
    }
}
FormJaivana.addController("most-consultacostoexistencia", CustomConsultaExistenciasCostos); 
export default CustomConsultaExistenciasCostos 
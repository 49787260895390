import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomPortafolio
 * @author: Maria F Villoria Posso
 * @version: jdesk_1.01.0006
 **/
class CustomPortafolio extends FormJaivana.form {

    constructor(props) {

        super(props);
        this.initForm = this.initForm.bind(this);

        this.ConsultarP = this.ConsultarP.bind(this);
        this.successConsultarP = this.successConsultarP.bind(this);
        this.setButtonEliminar = this.setButtonEliminar.bind(this);
        this.Eliminar = this.Eliminar.bind(this);
        this.ConfirmDelete = this.ConfirmDelete.bind(this);
        this.successEliminar = this.successEliminar.bind(this);
        this.btn_agregar = this.btn_agregar.bind(this);
        this.ValidarCampos = this.ValidarCampos.bind(this);
        this.successCrearR = this.successCrearR.bind(this);
        this.Cancelar = this.Cancelar.bind(this);
        this.limpiarCampos = this.limpiarCampos.bind(this);
        this.successValidarP = this.successValidarP.bind(this);
        this.limpiarErrores = this.limpiarErrores.bind(this);

        this.gridOptionsTablaPortafolio = Object.assign({}, this.gridOptions);
    }

    initForm() {
        console.log("Formulario CustomPortafolio,  @version: jdesk_1.01.0006, @author: Maria F Villoria Posso");

        if (this.props.data[0].opcion_sub_seccion === 1) {
            this.getField('nombre_formulario').setValue("PRODUCTOS EXCLUIDOS SUCURSALES");
            this.getField('cod_sucursal').setVisible(true);
            this.getField('nom_sucursal').setVisible(true);
            this.getField('cod_sucursal').setOnChange((props) => {
                this.getField('consultar').setDisabled(false);
                this.getField('Agregar').setVisible(false);
                this.getField('tablaItemsp').toggle(false);
                this.Cancelar();
            });
            this.getField('consultar').setVisible(true);
            this.getField("consultar").setClick((props) => {
                this.limpiarErrores();
                this.getField('tablaItemsp').toggle(false);
                this.Cancelar();
                this.ConsultarP();
            });
            this.getField("Agregar").setClick(this.btn_agregar);
            this.getField("Aceptar").setClick(this.ValidarCampos);
            this.getField('Cancelar').setClick(this.Cancelar);
        }

    }

    ConsultarP() {
        if (this.getField('cod_sucursal').valid() && this.getField('nom_sucursal').valid()) {
            this.getField('consultar').setDisabled(true);
            this.getField('Agregar').setVisible(true);
            let datos = {
                datos: {
                    id: this.getField('id_sucursal').getValue()
                }
            };
            this.generalFormatPmv = { tipo_servicio: 'maes-portafolio', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successConsultarP,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    successConsultarP(data) {
        if (data.estado_p === 200) {
            this.getField('tablaItemsp').toggle(true);
            let configCell = new Map();
            this.gridOptionsTablaPortafolio['rowData'] = data.data;
            configCell.set('eliminar', { cellRenderer: this.setButtonEliminar, width: 110, sortable: false, filter: false, field: 'eliminar' });
            this.getField('tablaItemsp').initData(this.gridOptionsTablaPortafolio, configCell);
        } else if (data.estado_p === 404) {
            this.getField('tablaItemsp').toggle(false);
        }
    }

    setButtonEliminar(props) {
        let id = props.data.id;
        let button = document.createElement("input");
        button.onclick = () => this.Eliminar(id);

        button.setAttribute("id", 'button_delete_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Eliminar");
        return this.createElementJaivana(button);
    }

    Eliminar(id) {
        this.ids = id;
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', '¿Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(this.ConfirmDelete);
        this.getField('confirmModalCustom').toggle(true);
    }

    ConfirmDelete() {
        this.getField('confirmModalCustom').toggle(false);
        let datos = {
            datos: {
                id: this.ids,
            }
        };
        this.generalFormatPmv = { tipo_servicio: 'maes-portafolio', operacion: '7', operacion_tipo: "eliminar" };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'DELETE',
                body: datos,
                success: this.successEliminar,
                error: this.error_,
                general: this.generalFormatPmv //se envia el generales de arriba
            });
    }


    successEliminar(data) {
        if (data.estado_p === 200) {
            this.limpiarCampos();
            this.ConsultarP();
        } else if (data.estado_p === 404) {
            this.alertGeneral.toggle(true, 'El registro no pudo ser eliminado', "error");
        }

    }

    btn_agregar() {
        this.getField('cod_articulo').setVisible(true);
        this.getField('nom_articulo').setVisible(true);
        this.getField('usuario').setVisible(true);
        this.getField('nom_usuario').setVisible(true);
        this.getField('Aceptar').setVisible(true);
        this.getField('Cancelar').setVisible(true);
    }

    ValidarCampos() {
        if (this.getField('cod_articulo').valid() && this.getField('nom_articulo').valid()
            && this.getField('usuario').valid() && this.getField('nom_usuario').valid()) {
            this.getField('Aceptar').setDisabled(true);
            this.datos = {
                datos: {
                    id_sucursal: this.getField('id_sucursal').getValue(),
                    articulo_id: this.getField('articulo_id').getValue(),
                    usuario_id: this.getField('usuario_id').getValue()
                }
            };

            this.generalFormatPmv = { tipo_servicio: 'maes-portafolio', operacion: 'validarUnico', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: this.datos,
                    success: this.successValidarP,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }
    }

    successValidarP(data) {
        if (data.estado_p === 200) {
            this.alertGeneral.toggle(true, 'El código del producto ingresado ya existe para esta sucursal.', 'error');
            this.getField('Aceptar').setDisabled(false);
        } else {
            this.generalFormatPmv = { tipo_servicio: 'maes-portafolio', operacion: '5', operacion_tipo: 'crear' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'POST',
                    body: this.datos,
                    success: this.successCrearR,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }
    }

    successCrearR(data) {
        if (data.estado_p === 200) {
            this.limpiarCampos();
            this.ConsultarP();
        } else {
            if (data.estado_p === 404) {
                this.alertGeneral.toggle(true, 'No se pudo crear el registro.', 'error');
            }
            else {
                let respuesta = Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
        this.getField('Aceptar').setDisabled(false);
    }

    Cancelar() {
        this.limpiarErrores();
        this.limpiarCampos();
        this.getField('cod_articulo').setVisible(false);
        this.getField('nom_articulo').setVisible(false);
        this.getField('usuario').setVisible(false);
        this.getField('nom_usuario').setVisible(false);
        this.getField('Aceptar').setVisible(false);
        this.getField('Cancelar').setVisible(false);
    }

    limpiarErrores() {
        this.getField('usuario').setError(false, '');
        this.getField('nom_usuario').setError(false, '');
        this.getField('cod_articulo').setError(false, '');
        this.getField('nom_articulo').setError(false, '');
    }

    limpiarCampos() {
        this.getField('articulo_id').setValue('');
        this.getField('usuario_id').setValue('');
        this.getField('usuario').setValue('');
        this.getField('nom_usuario').setValue('');
        this.getField('cod_articulo').setValue('');
        this.getField('nom_articulo').setValue('');
    }


}
FormJaivana.addController("maes-portafolio", CustomPortafolio);
export default CustomPortafolio;